// prevent ugly yellow background on autofilled inputs
input:autofill {
  background: transparent;
}

body.login {
  background-color: #026ea3;
  display: flex;
  width: 100vw; height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .wave-canvas {
    position: absolute;
    width: 100vw;
    top: 0;
    @media only screen and (max-width: 1120px) {
      top: 40px;
    }
    @media only screen and (max-width: 710px) {
      top: 80px;
    }
  }

  div.wavespacer {
    position:absolute;
    top: 0;
    width: 100vw;
    background-color: #0ac5e6;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1120px) {
      height: 40px;
    }
    @media only screen and (max-width: 1120px) {
      height: 80px;
    }
  }


  .content-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 60vw;
    height: 100vh;
    // background-color: white;
    border-radius: 20px;
    // box-shadow: 0px 0px 10px grey;
    justify-content: center;
    align-items: center;

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      min-width: 380px;
      border: 1px solid rgb(9, 145, 179);
      border-radius: 15px;
      background-color: #026ea3;
      z-index: 1;

      .login-input {
        width: 100%;
        height: 40px;
        margin-bottom: 40px;
        font-size: 24px;
        color: white;
      }

      .submit-button {
        // width: 150px;
        font-size: 20px
      }
    }

    .ms-signin-container {
      display: flex;
      margin-top: 60px;
      border: 1px solid rgb(9, 145, 179);
      border-radius: 15px;
      background-color: #026ea3;
      z-index: 1;
      padding: 20px;
      width: 380px;
      justify-content: center;
      align-items: center;

      .login-button {
        cursor: pointer;
        background-color: transparent;
        border-width: 0;
        background-color: transparent;
        border: 0;
      }
    }

    .header {
      font-size: 24px;
      text-align: center;
      // background-color: blue;
      width: 100%;
      position: absolute;
      height: 50px;
      top: 70px;
      align-self: center;
      color: #374d89;
    }
  }

  img.wave-logo {
    position:absolute;
    height: 100px;
    bottom: 20px;
    left: 140px;

    &.proasys {
      left: 20px;
    }
  }
}
