.autocomplete-container {
  min-height: 540px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // z-index: -999;

  &.active {
    z-index: 999; 
  } 

  input.search-input {

    background-color: white;
    min-width: 600px;
    width: 100%;
    height: 60px;
    border-radius: 15px;
    padding-left: 15px;
    font-size: 24px;

    @media only screen and (max-width: 700px) {
      min-width: 400px;
    }

    &.active {
      box-shadow: 0px 0px 100px 5px rgb(0, 0, 0) !important;
    }
  }

  .results-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    transition: opacity .2s;

    &.shown {
      opacity: 1;
    }

    .search-result {
      font-size: 20px;
      padding: 10px;
      border-radius: 15px;
      border: none !important;
      margin: 2px;
      box-shadow: 0 0 5px 0 black;

      &.exact-match {
        color: rgb(0, 117, 87);
      }

      span.subtext {
        color: grey;
      }
    }
  }
}