div.dash {
  display: flex;
  flex-direction: column;
  height: 100vh; width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #313131;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button.option, a.option {

    position: relative;
    display: flex;
    width: 500px;
    min-width: 500px; height: 250px;
    margin: 30px;
    border-radius: 10px;
    font-size: 36px;
    justify-content: space-between;
    align-items: center;
    padding: 25px 40px;
    transition: background-color .2s ease-out;
    box-shadow: 0px 0px 5px rgb(0, 0, 0) !important;

    &[disabled] {
      box-shadow: none;
    }

    // align-items: center;

    div.icon-container {
      height: 100%;
      display: flex;
      align-items: center;

      img.icon {
        height: 100%;
        top: 0;
      }
    }

    span.title {
      // font-family: 'roboto';
      position: relative;

      height: 100%;
      display: flex;
      align-items: center;
      color: white;
    }


    &.report {
      background-color: #3d21ff;
      &:hover {
        background-color: #803eff;
      }

      &:active {
        background-color: #ab7eff;
      }
    }

    &.order {
      background-color: #2abd6c;
      &:hover {
        background-color: #008307;
      }

      &:active {
        background-color: #73ff91;
      }
    }

    &.schedule {
      background-color: #a5101d;
      &:hover {
        background-color: #d86b28;
      }

      &:active {
        background-color: #ffa871;
      }
    }

    &.lookup {
      background-color: #00629b;
      &:hover {
        background-color: #00b6ce;
      }

      &:active {
        background-color: #68edff;
      }
    }

    .icon {
      font-size: 40
    }
  }
}
