.nav-wrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;

  &.reports {
    background: linear-gradient(149deg, rgba(61,33,255,1) 26%, rgba(128,62,255,1) 56%, rgba(61,33,255,1) 87%);
  }
  &.lookup {
    background: linear-gradient(149deg, rgb(32, 45, 161) 26%, rgb(9, 123, 199) 56%, rgb(32, 45, 161) 87%);
  }
  &.schedule {
    background: linear-gradient(149deg, #b8006e 26%, #661c49 56%, #b8006e 87%); 
  }

  .nav-sidebar {
    display: flex;
    flex-direction: column;
    width: 60px; min-width: 60px; height: 100vh;
    overflow: hidden;
    padding: 90px 10px 10px 5px; 
    transition: min-width .2s, padding .2s;

    &:hover {
      min-width: 200px !important;
      padding-left: 20px !important;
    }

    .nav-btn {
      position: relative;
      display: flex;
      font-size: 26px;
      color: white;
      opacity: 0.7;
      width: 200px;
      margin-bottom: 10px;
      height: 50px;
      padding-left: 15px;
      align-items: center;
      border-radius: 25px 0 0 25px;
      transition: opacity .1s;

      .nav-icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        fill: currentColor;
        color: inherit;
      }

      &.active {
        background-color: #e3edf5;
        opacity: 1;

        &.reports {
          color: rgba(61,33,255,1);
        }

        &.lookup {
          color: rgb(32, 45, 161);
        }

        &.schedule {
          color: #b8006e
        }
      }

      &:hover {
        opacity: 1;
      }

      .external-link {
        position: absolute;
        right: 40px;
        top: 18px;
        color: white;
        height: 20px;
        opacity: 0.6;
        fill: currentcolor
      }
    }

    button {
      background-color: transparent;
      border-width: 0;
    }

    .spacer {
      flex: 1;
    }
  }

  .everything-else {
    display: flex;
    flex: 1;
    flex-direction: column;

    .nav-header {
      display: flex;
      font-size: 40px;
      color: white;
      // margin-left: 220px;
      height: 10px;
      justify-content: flex-start;
      // background-color: blue;
  
      .header-text {
        padding-left: 220px;
        margin-right: 20px;
      }
    }

    .page-wrapper {
      display: flex;
      position: relative;
      background-color: #DFE4EC;
      border-radius: 30px;
      flex: 1;
      margin: 0px 10px 10px 0px;
      overflow: scroll;
    }
  }
}