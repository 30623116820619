body {margin: 0}

.app-container {
  flex: 1;
  display: flex; display: -webkit-flex;
  flex-direction: column;
  background-color: #DFE4EC;
  height: 100vh;

  .report-header {
    font-size: 18px;
    width: 100%;
    background-color: #0a55a4;

    color: white;
    flex-shrink:0;
    -webkit-box-shadow: 2px 15px 20px -17px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 15px 20px -17px rgba(0,0,0,0.75);
    box-shadow: 2px 15px 20px -17px rgba(0,0,0,0.75);
    justify-content: space-around;
    align-items: flex-start;
    display: flex; display: -webkit-flex;
    z-index:20;
    position: relative;
    overflow: hidden;
    align-items: flex-end;
    height: 50px;
    transition: height .2s;

    &.hidden {
      height: 0px;
    }
    
    &.submitted {
      background-color: #148016;
    }

    @media only screen and (max-width: 830px) {
      font-size: 16px;
      display: flex;
    }

    .logo-container {
      background-image: url("https://proasys-assets.nyc3.digitaloceanspaces.com/PROASYS_O_white_desat.png");
      background-size: 70px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      // margin-left:20px;
      position: absolute;
      left: 0;
      height: 100%;
      width: 120px;
      opacity: 0.5;
      transition: opacity .2s;

      &:hover {
        opacity: 1;
        // height: 120px;
      }

      @media only screen and (max-width: 830px) {
        display: none !important
      }
    }

    .header-content {
      padding: 10px; display: flex; display: -webkit-flex; flex: 2;
      flex-direction: column;
      text-shadow: 2px 2px 1px black;
      align-items: flex-start;
      cursor: pointer;
      margin-left: 110px;

      @media only screen and (max-width: 830px) {
        margin-left: 0px;
      }
    }
  }

  .report-title-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;

    form {
      width: 100%;
      display: flex;

      input.title-input {
        color: white;
        font-size: 20px;
        min-width: 400px;
        height: 30px;
        text-shadow: 2px 2px 1px black;
        margin-bottom: 0;
      }

      .submit-title {
        font-size: 30px;
      }
    }

    .edit-title {
      margin-left: 15px;
      color: #a6a6a6;
    }
  }

  .content-container {
    display:flex;
    overflow-y:hidden;
    height:100%;
    position: relative;

    .components-sidebar {
      font-family: Roboto;
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #d2d2d2;
      width: 300px;
      height: 100%;
      -webkit-box-shadow: 10px 0px 19px -1px rgba(0,0,0,0.25);
      -moz-box-shadow: 10px 0px 19px -1px rgba(0,0,0,0.25);
      box-shadow: 10px 0px 19px -1px rgba(0,0,0,0.25);
      z-index: 99;
      position: relative;
      overflow-y: visible;
      transition: left .1s;
      left: 0px;

      @media only screen and (max-width: 830px) {
        position: absolute;
        left: -300px;
      }

      &.hidden {
        position: absolute;
        left: -300px;

        @media only screen and (max-width: 830px) {
          left: 0px;
        }
      }

      .sidebar-toggle {
        padding: 10px;
        position: absolute;
        // z-index: 99;
        right: 0; top:0;
        color: #3A4E85;

        svg {
          position: absolute;
          left: 30px;
          font-size: 36px;
        }
      }

      .components-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        overflow: scroll;

        .component-group {
          display: flex; display: -webkit-flex;
          background-color: white;
          flex-direction: column;

          .visibility-toggle {
            display: flex;
            width: 30px;
            height: 20px;
            border-radius: 10px;
            border: 1px solid rgb(121, 121, 121);
            // border: none;
            align-items: center;
            padding: 0 1px 0 1px;

            &::before {
              display: flex;
              content: "";
              height: 16px; width: 16px;
              border-radius: 8px;
            }

            &.visible {
              background-color: #d7ffd7;
              justify-content: flex-end;
              &::before {
                background-color: #07d410;
              }
            }

            &.invisible {
              background-color: #dddddd;
              justify-content: flex-start;
              &::before {
                background-color: #727272;
              }
            }
          }
  
          .group-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: black;
            font-size: 28px;
            padding: 10px 20px 10px 20px;
            border: 0px solid #cccccc;
            border-bottom-width: 1px;
            max-height: 60px;
          }

          .component {
            cursor: pointer;
            display: flex; display: -webkit-flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 5px 20px 5px 40px;
            color: black;
            font-size: 20px;
            border: 0px solid #cccccc;
            border-bottom-width: 1px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            svg {
              color: black;
              font-size: 24px;
  
              &.hidden {
                color: grey;
              }
            }
    
            i.status-indicator {
              margin-right: 5px;
    
              &.si-green {
                color: green;
              }
    
              &.si-orange {
                color: #DC984E;
              }
    
              &.si-empty {
                margin-right: 30px;
              }
            }
    
            p.component-name {
              text-overflow: ellipsis;
              overflow: hidden;
              margin: 0;
            }
    
            i.note-indicator {
              color: #b0b0b0;
            }
          }
        }
      }
    
      .next-report, .previous-report {
        border-top: 1px solid #ccc;
        i {
          font-size:40px;color:#9d9d9d;
        }

        span {
          display:flex;flex:1;font-size:22px;justify-content:center;
        }
      }

      .next-report {
        border-bottom:none;
      }

      .recipient-select-button, .submit-button, .preview-report-button {
        display: flex;
        cursor: pointer;
        background-color: #008d63;
        border: 1px solid #00422e;
        color: white !important;
        margin-bottom: 1px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 22px;
        height: 50px;
        font-family: Roboto;
      }

      .recipient-select-button {
        background-color: #008d63;
        border: 1px solid #00422e;
      }

      .submit-button {
        background-color: #2B5FA5;
        border: 1px solid #0D4093;

        &:disabled {
          color:rgb(102, 102, 102);
          background-color: #424242;
        }
      }

      .preview-report-button {
        background-color: #ad9300;
        border: 1px solid #806200;
      }
    }

    .report-body {
      padding-bottom: 0; padding-top:10px;
      align-items: center;
      height: 100%;
      display:flex;
      flex-direction: column;
      flex: 1;
      background-color: #C4CADF;
      width: 100%;
      overflow-y: scroll;
      position:relative;
      text-align: center;

      .report-results-container {
        width: 100%;
        margin-bottom: 10px;
        display: flex; display: -webkit-flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0 0;
        border-top: 1px solid gray;
        overflow: visible;

        &.hidden {
          height: 0;
          margin: 0;
          display: none;
        }

        &.service-log {
          background-color: #a4acd7;
          padding-bottom: 30px;

          .invisible {
            color: rgb(112, 131, 170);
            font-size:30px;
            margin-left: 10px;
            position: absolute;
            top: 10px;
          }
        }

        &.dragging-active {
          cursor: grabbing;
          user-select: none;
        }

        .grabbable {
          width: auto !important;
        }

        .active-component-header {
          font-size: 30px;
          position: sticky;
          top: -15px;
          background-color:#c4cadfcc;
          padding: 0 20px;
          z-index: 10;
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;

          &.service-log {
            background-color: #a4acd7;
          }
        }

        .log-date {
          font-size: 24px;
          color: #4f5b88;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }

      .report-footer {
        // display: flex; display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        width:100% !important;
        max-width: 100% !important;
        background-color: #0a55a4;
        font-size: 18px;
        padding: 20px 0 20px 0;

        .report-metadata {
          text-shadow: 2px 2px 1px black;
          color: white;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          
          span.report-author {
            font-size: 18px;
            color: rgb(161, 187, 221);
          }
        }

        .halfsize-buttons {
          width: 410px;
          display: flex;

          .half {
            height: 40px;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
          }
        }

        .footer-button {
          margin: 5px;
          width: 400px;
          height: 50px;
          box-shadow: 0px 0px 5px rgb(0, 0, 0);
          border-radius: 10px;
          color: white;
          cursor: pointer;
          transition: background-color .2s, box-shadow .2s;

          &.recipients {
            background-color: #009468;
            border: 1px solid #86ffdb;

            &:hover {
              background-color: #00ce90;
              box-shadow: 0 0 10px black;
            }
          }

          &.submit {
            background-color: #3b7fd8;
            border: 1px solid #92bcff;

            &:hover {
              background-color: #79b3ff;
              box-shadow: 0 0 10px black;
            }

            &:disabled {
              color:rgb(102, 102, 102);
              background-color: #424242;
              box-shadow: 0 0 5px black !important;
            }
          }

          &.preview {
            background-color: #cfb000;
            border: 1px solid #ffebab;

            &:hover {
              background-color: #ffdf28;
              box-shadow: 0 0 10px black;
            }
          }
        }
      }
    }
  }
}

// report nodes

.node-container {
  // display: flex; display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  text-align: initial;
  max-width: 100%;
  position: relative;
  user-select: none;
  cursor: grab;
  transition: transform 0.2s ease;
  user-select: none;

  &.dragging {
    opacity: 0.6;
    cursor: grabbing;
    position: relative;
    z-index: 1000;
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  }

  &:not(.dragging) {
    transform: translateY(0);
    
    &:hover {
      background-color: rgba(0,0,0,0.02);
    }
  }

  // Add a visual indicator for the drop target
  &:not(.dragging):not([data-type="pseudonode"]) {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background: transparent;
      transition: background-color 0.2s ease;
    }

    &.drop-above::after {
      top: -1px;
      background-color: #2196f3;
    }

    &.drop-below::after {
      bottom: -1px;
      background-color: #2196f3;
    }
  }

  .report-node {
    cursor: pointer;
    margin-left: 5px; margin-right:5px;
    display: flex; display: -webkit-flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px grey;
    flex-shrink: 0;
    width: 350px;
    min-height: 50px;
    color: black;
    transition: width .2s, background-color .1s;

    &:hover {
      width: 360px;
    }

    &:hover > .node-test > .result-container > label:before {
      // lord forgive me for this sin
      width: 0px;
      height: 0px;
      box-shadow: inset 0 7px 10px #ff9d96;
    }

    &:active {
      background-color: #dddddd;
    }

    &.image {
      width: 420px;
      display: flex;

      .note-content {
        margin-top: 5px;
        font-size: 16px;
        color: grey;
        font-style: italic;
        text-align: center;
      }
    }

    .node-test {
      pointer-events: none;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .test-field {
        // display: flex; display: -webkit-flex;
        justify-content: center;
        flex-direction: column;

        span.test-name {
          font-size: 16px;
          color: #374ab0;
          font-weight: bold;
        }

        span.limits {
          color: grey;
          white-space: nowrap;
        }

        span.additional {
          color: grey;
        }
      }

      .result-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .range-indicator-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 20px;
          height: 100%;
          transition: height .2s, background-color .2s;
          // background-color: orange;

          .range-indicator {
            width: 10px;
            height: 10px;
            border-radius: 5px;
          }
        }

        .result {
          display: flex;
          flex: 1;
          font-size: 24px;
          // margin-left: 10px;
          min-width: 80px; max-width: 160px;
          text-align: center;
          width: 0;
        }

        // https://codepen.io/alvarotrigo/pen/gOvOeNN
        .result-checkbox {
          display: none;
        }

        label.checkbox-label {
          pointer-events: none;
          position: absolute;
          top: 50%;
          right: 0;
          width: 40px;
          height: 40px;
          margin: 0 auto;
          background-color: #f72414;
          transform: translateY(-50%);
          border-radius: 50%;
          cursor: pointer;
          transition: 
            0.2s ease transform, 
            0.2s ease background-color,
            0.2s ease box-shadow;
          overflow: hidden;
          z-index: 1;
          border: 2px solid rgba(0, 0, 0, 0.44);  
        }

        label:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          width: 30px;
          height: 30px;
          margin: 0 auto;
          background-color:#C4CADF;
          transform: translateY(-50%);
          border-radius: 50%;
          // box-shadow: inset 0 7px 10px #dddddd;
          box-shadow: inset 0px 0px 5px grey;
          transition: 0.2s ease width, 0.2s ease height;
        }
        
        label:hover:before {
          width: 0px;
          height: 0px;
          box-shadow: inset 0 7px 10px #ff9d96;
        }
        
        label:active {
          transform: translateY(-50%) scale(0.9);
        }

        .tick-mark {
          position: absolute;
          top: -1px;
          right: 0;
          left: 0;
          width: 25px;
          height: 25px;
          margin: 0 auto;
          margin-left: 3px;
          transform: rotateZ(-40deg);
        }
        
        .tick-mark:before,
        .tick-mark:after {
          content: "";
          position: absolute;
          background-color: #fff;
          border-radius: 2px;
          opacity: 0;
          transition: 0.2s ease transform, 0.2s ease opacity;
        }
        
        .tick-mark:before {
          left: 0;
          bottom: 0;
          width: 5px;
          height: 15px;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
          transform: translateY(-68px);
        }
        
        .tick-mark:after {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
          transform: translateX(78px);
        }
        
        .result-checkbox:checked + label {
          background-color: #07d410;
          box-shadow: 0 0px 10px 2px #92ff97;
        }
        
        .result-checkbox:checked + label:before {
          width: 0;
          height: 0;
        }
        
        .result-checkbox:checked + label .tick-mark:before,
        .result-checkbox:checked + label .tick-mark:after {
          transform: translate(0);
          opacity: 1;
        }        

        span.width-calc {
          font-size: 24px;
          display: none;
        }
      }
    }

    i.test-note {
      // text-indent:10px;
      display: none;
      color: #374ab0;

      &.shown {
        display: flex;
      }
    }

    div.test-note-container {
      display: flex;
      flex-direction: row;
      display: none;

      &.shown {
        display: flex;
      }

      .test-note-input {
        border-radius: 5px;
        border: 2px solid #0a55a4;
        align-items: center;

        &::placeholder {
          color: rgb(138, 138, 138);
          font-style: italic;
        }
      }

      .submit-note {
        height: 42px;
        margin-left: 2px;
        background-color: #0a55a4;
        color: white;
        border: 0px;    
        border-radius: 5px;
      }
    }

    .limits-input-container {
      display: flex;
      flex-direction: row;
      padding-top: 5px; 
      
      input {
        height: 20px; width: 80px;
        margin-right: 20px; margin-left: 5px;
        text-align: center;
      }
    }

    .history-container {
      margin-top: 10px;
      display: none;
      flex-direction: column;

      &.shown {
        display: flex;
      }

      canvas.result-history {
        // border: 1px solid black;
        margin-top: 5px;
        width: 100%;
      }

      .x-axis {
        display: flex;
        flex-direction:row;
        align-items: end;
        margin-top: 2px;

        .y-value {
          display: flex; flex: 1; justify-content: center; position: relative; top: -5px;
        }
      }
    }

    .delete-node {
      position:absolute;
      height: 100%; width: 100%;
      background-color: rgba(255, 255, 255, 0.767);
      left: 0px;
      border-radius: 10px;
      display: none;
      justify-content: space-around;
      align-items: center;
      z-index:99;

      .delete-text {
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: rgb(124, 0, 0);
        text-shadow: 0px 0px 5px #ff0000;
        font-size: 20px;
        transition: font-size .2s;

        &:hover {
          font-size: 22px;
        }
      }

      .never-mind {
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: rgb(0, 153, 255);
        text-shadow: 0px 0px 5px #008ca5;
        font-size: 20px;
        transition: font-size .2s;

        &:hover {
          font-size: 22px;
        }
      }

      &.shown {
        display: flex;
      }

      svg {
        font-size: 60px;
        color: #d6b2b2;
        // background-color: white;
        border-radius: 10px;
      }
    }
  }

  .options {
    display: flex;
    justify-content: center;
    flex-direction: column;

    button.node-option {
      position: absolute;
      justify-content: center;
      align-items: center;
      padding: 5px;
      top: 5px;
      display: flex;
      width: 75px;
      flex: 1;
      border-radius: 5px;
      border: 0px;
      box-shadow: rgb(128, 128, 128) 0px 0px 5px 0px;
      transition: left .2s, right .2s, background-color .2s;

      &.result-history-toggle {
        background-color: #eec834;
        left: 10px;

        &.shown {
          left: -75px;
        }
        &:hover {
          background-color: #fbde6b;
        }
        &:active {
          background-color: #deb515;
        }
      }

      &.notes-toggle {
        background-color: #7fe367;
        left: 10px;
        top: 40px;
        // height: 50%;
        &.shown {
          left: -75px;
        }
        &:hover {
          background-color: #c0ffb0;
        }
        &:active {
          background-color: #54b03e;
        }
      }

      &.limits-edit-toggle {
        font-family:Roboto;
        background-color: #007fc9;
        right: 10px;
        color: rgb(180, 228, 255);
        // height: 50%;
x
        svg {
          transform: rotate(90deg);
        }

        &.shown {
          right: -75px;
        }
        &:hover {
          background-color: #5ec4ff;
        }
        &:active {
          background-color: #94d8ff;
        }
      }

      &.delete-trigger {
        font-family:Roboto;
        background-color: #720000;
        right: 10px;
        top: 40px;
        color: rgb(255, 216, 216);
        // height: 50%;

        &.shown {
          right: -75px;
        }
        &:hover {
          background-color: #c0002a;
        }
        &:active {
          background-color: #ff9898;
        }
      }

      i {
        font-size: 30px;
        color: white;
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  .add-node {
    cursor: pointer;
    margin-left: 5px; margin-right:5px;
    display: flex; display: -webkit-flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 3px dashed #868686;
    // box-shadow: 0px 0px 5px grey;
    flex-shrink: 0;
    width: 350px;
    min-height: 60px;
    color: #1a3fab;
    font-size: 20px;
    transition: width .2s, background-color .1s;

    &:hover {
      width: 360px;
    }

    &:active {
      background-color: #aebfd9;
    }

    svg {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  .note-container {
    cursor: pointer;
    display: flex; justify-content: center;
    width: 400px;
    margin-top: 10px;
    position: relative;
    border-radius: 15px;
    padding: 5px;
    opacity: 1;
    transition: width .2s;

    &:hover {
      width: 420px;
    }

    &::before {
      content: "";
      background-image: url('quotemarks.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: scaleY(-1);
      width: 25px; height: 25px;
      opacity: 20%;
      position: absolute; left: -15px; top: 0;
    }

    &::after {
      content: "";
      background-image: url('quotemarks.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: scaleX(-1);
      width: 25px; height: 25px;
      opacity: 20%;
      position: absolute; right: -15px; bottom: 0;
    }

    .report-note {
      // padding: 0 10px 0 10px;
      display: flex;
      justify-content: center;
      width:auto;
      max-width:370px;
      min-width:300px;
      // color:#666;
      font-size:18px;
      text-align:justify;
      margin: 10px;
      font-style: italic;
    }
  }
}

form.onsite-form {
  display: flex;
  margin-bottom: 15px;

  .picker-input {
    cursor: pointer;
    color: white;
    text-align: right;
    margin: 0;
    height: 2em;
    border-width: 0;
    text-shadow: 2px 2px 1px black;
    color: white;
    background-color: rgba(255, 255, 255, 0.12);
    box-shadow: 0 0 5px black;
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
    transition: background-color .2s, box-shadow .2s;


    &:hover {
      background-color: rgba(255, 255, 255, 0.32);
      box-shadow: 0 0 10px black;
    }

    &:placeholder-shown {
      border-width: 1px;
    }
  }

  .datepicker {
    width: 110px;
    text-align: center;
  }

  .timepicker {
    width: 80px;
    text-align: center;
  }
}

// report modals

.report-modal, .edit-node-modal {
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #000000a1;

  .create-node {
    width: 400px;
    height: 75px;
    border-radius: 10px;
    border: 2px solid black;
    margin: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    color: #429fff;
    transition: width .2s;
    cursor: pointer;

    &:hover {
      width: 410px;
    }

    &:active {
      background-color: #cdcdcd;
    }
  }

  input.new-test-select {
    background-color: white !important;
    border-radius: 10px !important;
    padding: 10px !important;
    width: 50vw !important;
    font-size: 20px !important;

    &::placeholder {
      color: #9e9e9e;
      font-size: 20px;
    }
  }

  .edit-node-container {
    position: relative;
    display: flex;
    // align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    max-width: 600px;
    width: 90%;

    .note-input {
      min-height: 50px;
      font-size: 24px;
      border: 2px solid #8a8a8a;
      border-radius: 5px;
      padding: 8px;
    }

    .submit-note {
      cursor: pointer;
      height: 50px;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: #078b48;
      margin-left: 5px;
      color: white;
      border: 0;
    }

    .delete-note {
      cursor: pointer;
      margin-right: 10px;
      position: absolute;
      height: 50px;
      width: 50px;
      right: -70px;
      background-color: crimson;
      border: 0;
      border-radius: 5px;
      padding-top: 2px;
      
      svg {
        font-size: 40px;
        color: #690000
      }
    }
  }

  .dropzone-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: black;
    width: 500px; max-width: 90vw;
    height: 500px; max-height: 80vh;
    border-radius: 30px;
    border: 3px dashed #2152a4;
    background-image: url('./image-placeholder.jpg');
    background-position: center;
    background-size: 250%;
    opacity: 0.8;
    font-size: 30px;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 60px;
      margin-top: 40px;
      color: rgb(7, 0, 107)
    }

    p {
      text-shadow: 2px 2px 5px #ffffff;
    }
  }

  img.file-upload-preview {
    max-height: 80vh;
    max-width: 90vw;
    margin-bottom: 20px;
  }

  .recipient-select-container {
    max-height: 80vh;
    max-width: 90vw;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    width: 400px;
    overflow: scroll;

    .contact {
      cursor: pointer;
      padding: 10px;
      background-color: grey;
      margin-bottom: 2px;
      font-size: 18px;
      color: white;
      border: 1px solid rgb(75, 75, 75);
      border-radius: 5px;
      display: flex;
      align-items: center;
      transition: background-color .2s, color .2s;

      &.selected {
        background-color:rgb(177, 255, 158);
        color: #26a69a;
      }

      .checkbox-container {
        padding-top: 10px;
        padding-left: 10px;

        label {
          pointer-events: none;
        }
      }
    }

    .recipient-input-container{
      display: flex;
      flex-direction: row;
      position: relative;
      padding-top: 5px;

      .recipient-input {
        border: 1px solid black;
        border-radius: 5px;
        margin-right: 5px; margin-bottom: 0;
        height: 40px;
        padding-left: 10px;
      }

      .submit-recipient {
        height: 41px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #078b48;
        margin-left: 5px;
        color: white;
        border: 0;

        &:disabled {
          background-color: grey;
        }
      }
    }
  }

  form.onsite-form {
    background-color: #0056d7;
    border: 2px solid #002e74;
    border-radius: 10px;
    padding: 5px 15px;
    color: white;
    font-size: 18px;
    box-shadow: 0 0 10px black;
  }

  .presubmit-container {
    border: 2px solid rgb(175, 175, 175);
    box-shadow: 0 0 10px black;
    max-height: 80vh;
    max-width: 90vw;
    background-color: rgb(221, 221, 221);
    border-radius: 15px;
    padding: 20px; padding-top: 10px;
    width: 400px;
    overflow-y: scroll;

    .category-header {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      font-size: 20px;

      &.report-summary {
        font-weight: bold;
        font-size: 22px;
        text-decoration: underline;
        margin-bottom: 10px;
      }
    }

    .component-summary {
      background-color: white;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      border-radius: 5px;
      padding: 10px;
      box-shadow: rgb(0, 0, 0) 0px 0px 5px 0px;
      margin-bottom: 10px;

      .component-name {
        display: flex;
        justify-content: center;
        font-weight: bold;
        color:rgb(90, 90, 90);
      }

      .component-info {
        font-size: 16px;
        display: flex;
        justify-content: space-between;

        .summary {
          display: flex;
          flex-direction: column
        }

        .attention {
          color: rgb(255, 94, 0)
        }
      }
    }

    .recipients-container {
      padding: 5px;
      background-color: white;
      box-shadow: rgb(0, 0, 0) 0px 0px 5px 0px;
      border-radius: 5px;

      .recipient {
        background-color: white;
        padding: 2px;
        display: flex;
        justify-content: space-between;
        
        .remove-recipient {
          cursor: pointer;
          color: rgb(143, 0, 0);
          padding: 2px;
          border-radius: 5px;

          &:hover {
            background-color: rgb(170, 0, 0);
            color: rgb(255, 255, 255);
          }
        }
      }

      button.add-recipient {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;

        font-size: 18px;
        background-color: transparent;
        border: 2px dashed #00c943;
        border-radius: 5px;
        
        svg {
          font-size: 28px;
          margin-right: 5px;
          color: #008e2f;
        }
      }
    }

    .spacer {
      border: 0px dotted black;
      border-bottom-width: 2px;
      flex: 1;
      margin: 6px;
      height: 12px;
    }
  }

  .final-submit {
    width: 400px;
    font-size: 30px;
    padding: 10px;
    border-radius: 15px;
    border: 0;
    margin-top: 10px;
    background-color: #004cbe;
    border: 2px solid #4c93ff;
    color: white;
    box-shadow: rgb(0, 0, 0) 0px 0px 10px 0px;
    text-shadow: 0px 0px 2px black;
    transition: width .1s, background-color .1s;

    &:hover {
      width: 420px;
      background-color: #0066ff;
    }
  }

  .submit-success-container {
    width: 600px;
    height: 400px;
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    border: 0;
    box-shadow: rgb(255, 255, 255) 0px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;

    .header {
      justify-content: center;
      text-align: center;
      font-size: 40px;
      font-family: Roboto;
      font-weight: bold;
      height: 40px;
      margin-bottom: 10px;
    }

    .options-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-around;
      margin-top: 10px; margin-bottom: 20px;

      a {
        display: flex;
        width: 300px;
        height: 75px;
        border-radius: 10px;
        justify-content: center;
        text-align: center;
        align-items: center;
        color: white;
        font-size: 24px;
        box-shadow: rgb(90, 90, 90) 0px 0px 5px 0px;
      }
  
      a.view-link {
        background-color: rgb(0, 89, 255);
        
        &:hover {
          background-color: rgb(62, 130, 255);
        }
      }
  
      a.back-link {
        background-color: rgb(90, 0, 163);

        &:hover {
          background-color: rgb(114, 31, 182);
        }
      }
    }
  }
}

div.blank-node-container {
  display: none;
}

// universal styles
.flex-spacer {
  display: flex; flex: 1;
}

.note-image.placeholder {
  height: 200px;
  background-color: lightgray;
}

.report-node.green {
  background-image: linear-gradient(to right, white 75%, #3cbf00ad);
  box-shadow: 0px 0px 5px #4CAF50;
}
.report-node.orange {
  background-image: linear-gradient(to right, white 75%, #e19e00);
  box-shadow: 0px 0px 5px #e19e00;
}

.result {
  font-size: 24px;
  align-items: center;
  display: flex; display: -webkit-flex;
}

.history-toggle {
  margin-left: 10px;
  font-size:42px;
}

.history-toggle:hover {
  color: #0a55a4;
}

.divider {
  width: 100%;
  border-top: 1px solid grey;
}

.print-btn {
  width: 150px;
  border-radius: 5px !important;
  background-color: #2c6bcc !important;

  @media only screen and (max-width: 830px) {
    display: none !important;
  }
}

.modal-content {
  max-width: 100%;
  max-height: 100%;

  @media only screen and (max-width: 830px) {
    padding: 5px;
  }
}

@media only screen and (max-width: 830px) {
  .modal {
    width: 100%;
  }
}

// SAFARI-ONLY CSS
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .report-body {
      display: block !important;
    }
  }
}

// reports/main
.report-index {
  font-family: Roboto;
  flex: 1;
  display: flex;

  .dash-container {
    background-color: #DFE4EC;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .tab-container {
      z-index: 1;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      padding: 20px 30px 30px 30px;
      gap: 30px;

      @media only screen and (max-width: 620px) {
        gap: 15px;
        padding: 20px 15px 15px 15px;
      }

      .tab-option {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        width: 140px;
        height: 120px;
        margin-bottom: 20px;
        justify-content: flex-start;
        // align-items: center;
        border-radius: 15px;
        color: #8893AA;
        background-color: #eef2f8;
        border: 2px solid transparent;
        // cursor: pointer;

        &:hover {
          border-color: #3d21ff;
        }

        .flex-column {
          width: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .side-icon {
            font-size: 40px;
            color: inherit;
          }
        }

        &.selected {
          background-color: #3d21ff;
          border: 5px solid #2e13dd;
          color: white;
          -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
        }

        .autocomplete-container {
          display: none;
          flex: 1;
          align-items: center;
          padding: 10px;
          color: white;

          input {
            color: white;
            font-size: 30px !important;
            margin-right: 30px;
          }
        }
      }
    }

    .content-container {
      // background-color: blue;
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0px 20px 20px 20px;
      overflow: scroll;
      align-items: center;
      width: 100%;

      .new-report-account-input {
        padding: 10px 15px 15px 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          background-color: #B1B1DB;
          color: #3c005d;
          font-size: 24px;
          padding: 15px;
          border-radius: 15px;
          max-width: 600px;
          -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);

          &::placeholder {
            color: #34275b;
          }

          &:focus {
            border: 2px solid #3d21ff;
          }
        }
      }

      .under-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .create-report-button {
          width: 250px; height: 100px;
          border-radius: 15px !important;
          -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
          font-size: 24px;
          &:disabled {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }

          &:active {
            background-color: #2fa69f;
          }
        }

        .check-container {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 20px;
          padding: 15px;
        }
      }

      .report-draft-container, .customer-drafts-container {
        display: flex;
        height: 200px;
        margin-top: 20px;
        flex-wrap: wrap;
        font-family: Roboto;
        // border: 1px solid black;

        .report-draft {
          display: flex;
          flex-direction: column;
          position: relative;
          height: 100%;
          width: 400px;
          min-width: 400px;
          background-color: white;
          border-radius: 15px;
          // -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          margin-right: 30px;
          // padding: 5px 15px 5px 15px;
          cursor: pointer;
          margin-bottom: 20px;
          overflow: hidden;

          .draft-header {
            padding: 5px 10px 2px 10px;
            // display: flex;
            min-height: 30px;
            // justify-content: center;
            align-items: center;
            font-size: 20px;
            border-bottom: 1px solid grey;
            background-color: #0a55a4;
            color: white;
            padding-bottom: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            align-items: flex-start;
            white-space: nowrap;
            font-weight:bold;
            text-align: center;
            align-items: center;
            justify-content: center;
            z-index: 3;
          }

          .draft-content {
            // max-height: 170px;
            display: flex;
            flex-direction: column;
            // background-color: #07d410;
            z-index: 1;
            width: 100%;
            // background-color: green;

            .component-summary, .draft-info {
              // bottom: 100%;
              // background-color: green;
              height: 170px;
              flex-direction: column;
              flex: 1;
              margin-bottom: 0px;
              
              transition: bottom .1s;
              justify-content: flex-start;
  
              .component, .info {
                display: flex;
                width: 100%;
                padding-left: 10px;
                flex-direction: row;
                font-size: 16px;
              }
  
              &.hidden {
                bottom: -170px;
              }
            }
          }

          .button-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .draft-button {
              border-radius: 0px;
              display: flex;
              flex: 1;
              height: 40px;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      &.report-history-table {
        margin-top: 20px;
      }

      pre {
        margin: 0px;
        padding: 10px 0px 10px 60px;
        background-color: #F2F2F4;

        .component-row {
          display: flex;
          align-items: center;

          .component-okay {
            color: #38c300;
            font-size: 28px;
            margin-right: 5px;
          }

          .component-attention {
            color: #b17000;
            font-size: 28px;
            margin-right: 5px;
          }
        }
      }

      .rdt_TableRow {
        cursor: pointer;
      }

      .expanded-content-container {
        display: flex;
        flex-direction: row;

        .component-summary {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .report-recipients {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
      }
    }
  }
}

button {
  cursor: pointer;
}

// KEYFRAMES

@keyframes fadein {
  from { opacity: 0; top: 30px;}
  to   { opacity: 1; top: 0px;}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; top: 30px;}
  to   { opacity: 1; top: 0px;}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; top: 30px;}
  to   { opacity: 1; top: 0px;}
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; top: 30px;}
  to   { opacity: 1; top: 0px;}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; top: 30px;}
  to   { opacity: 1; top: 0px;}
}
