div.background-fade {
  position: absolute;
  top:0; left: 0;
  height: 100vh; width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 50%;
  background-color: black;
  z-index: 999;
  font-size: 20px;
  color: white;
}

img.loading-spinner {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}