
$overdue-color: #aa0041;
$completed-color: #00aa69;
$unfinished-color: #005baf;


.schedule-container {
  height: 100%; 
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #DFE4EC;
  width: 100%;
  transition: background-color .2s;

  &.dark {
    background-color: #a4a8ad;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .calendar-container {
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    min-width: 730px;
    flex-direction: column;

    @media only screen and (max-width: 800px) {
      padding: 15px 2px 0 2px;
      min-width: unset;
    }

  
    .react-calendar {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      // align-items: center;
  
      .react-calendar__navigation {
        display: flex;
        align-content: center;
        height: 40px;
        justify-content: center;
      }

      .react-calendar__navigation__label, .react-calendar__navigation__arrow {
        background-color: white;
        border: 0;
        border-radius: 5px;
        box-shadow: 0px 0px 4px #9fa1a7;
        padding: 15px;
        font-size: 20px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .2s, box-shadow .2s;

        &:hover {
          background-color:rgb(219, 219, 219);
          box-shadow: 2px 2px 4px #9fa1a7;
        }
      }

      .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
        display: none;
      }

      .react-calendar__navigation__label {
        padding: 20px;
        position: relative;
        top: -5px;
      }
  
      .react-calendar__month-view__weekdays__weekday {
        text-align: center;
  
        abbr {
          text-decoration: none;
        }
      }
  
      .react-calendar__tile {
        display: flex;
        flex: 1;
        min-height: 100px;
        width: 100px;
        text-align: left;
        align-content: flex-start;
        justify-content: flex-start;
        border-radius: 0;
        border: 1px solid #c5c5c5;
        padding: 0;
        background-color: white;
        z-index: 0;
        box-shadow: none;
        transition: box-shadow .2s;
        flex-wrap: wrap;
        position: relative;

        &:active {
          background-color: lightgrey;
        }
  
        &:hover {
          z-index: 1;
          box-shadow: 0px 0px 5px grey;
        }

        @media only screen and (max-width: 800px) {
          min-height: 60px;

        }
  
        abbr {
          color: #777;
          text-shadow: 0px 0px 3px rgb(255, 255, 255);
          padding-right: 2px;
          padding-bottom: 2px;
          background-color: #DFE4EC;
          font-size: 18px;
          height: 30px; width: 30px;
          border-bottom-right-radius: 15px;
          justify-content: center;
          align-items: center;
          display:flex;
          margin-right: 5px;

          @media only screen and (max-width: 800px) {
            font-size: 12px;
            height: 24px; width: 24px;
          }
        }

        .event {
          color: white; font-size: 14px;
          min-width: 18px; height: 18px;
          border-radius: 9px;
          margin: 3px 3px 2px 2px;
          padding: 2px 8px;

          @media only screen and (max-width: 800px) {
            height: 6px; width: 6px; border-radius: 3px; 
            font-size: 0;
            min-width: 0;
            padding: 0;
          }
        }
      }
    }

    .henchmen-select-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      span {
        font-size: 16px;
        margin-bottom: 10px;
        margin-right: 10px;
        font-weight: bold;
      }

      .input-field {
        margin:0;
      }
    }
  }

  .account-view-container {
    background-color: #b4b4b4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    .heading {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }

    span.due {
      font-size: 18px;
    }
  }

  .accounts-container {
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 10px 15px 10px 0;
    z-index: 4;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      overflow: unset;
    }

    .accounts-toggle {
      display: flex;
      border-radius: 15px;
      overflow: hidden;
      height: 30px; min-height: 30px;
      min-width: 350px;

      button {
        padding: 6px;
        background-color: #661c49;
        color: rgb(192, 192, 192);
        border: 0;
        height: 30px;
        display: flex;
        flex: 1;
        text-align: center;
        justify-content: center;
        align-content: center;
        transition: color .2s, background-color .2s;

        &.active {
          background-color: #b8006e;
          color: white;
        }
      }
    }

    .reactive-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
  
      .category {
        display: flex;
        flex-direction: column;
        max-height: unset;
        width:100%;
        flex: 1;
        margin: 5px;
        min-width: 300px;
  
        button.content-toggle {
          color: grey;
          display: flex;
          border: 0;
          height: 40px;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          background-color: transparent;
          margin-bottom: 5px;
        }
  
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .account-button {
            display: flex;
            flex-direction: column;
            width: 100%; max-width: 370px;
            border: none;
            margin: 0px 2px 15px 2px;
            // height: 56px;
            min-width: 300px;
            position: relative;
            justify-content: flex-start;
            background-color: transparent;
            background-color: white;          
            box-shadow: 0px 0px 3px rgb(87, 87, 87);
            border-radius: 23px;
            padding: 10px;
            transition: background-color .2s;
  
            &.dimmed {
              background-color: rgb(133, 133, 133);
            }
  
            .svc-frequency {
              color: grey;
            }
  
            .account-name {
              text-align: left;
              padding: 5px 5px 5px 30px;
              width: 100%;
              margin: 0 0 5px 0;
              transition: background-color .2s, box-shadow .2s;
              transition: width .2s;
              background-color: rgb(133, 133, 133);
  
              p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
      
            .indicator {
              position:absolute;
              top: 5px; left: 10px;
              background-color: rgb(133, 133, 133);
              width: 30px;
              border-radius: 15px;
              color: white; font-size: 24px;
              transform-origin: 0,0;
              // transform:
            }
  
            .pill-box {
              width: 100%;
              display: flex;
              padding: 00px;
  
              .frequency {
                background-color: rgb(174, 174, 174);
              }
            }
          }
        }
      }
    }
  }

  .modal-backdrop {
    // display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0; left: 0;
    display: flex;
    height: 100%; width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 5;
    opacity: 100%;
    transition: opacity .2s;

    .modal-container {
      width: 50vw; min-width: 800px;
      margin: 10px;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 15px 20px;
      border-radius: 15px;
      z-index: 4;
      overflow-y: scroll;
      max-height: 90%;

      @media only screen and (max-width: 830px) {
        width: 75vw;
      }
      @media only screen and (max-width: 500px) {
        width: 100vw;
      }

      .heading {
        display: flex;
        font-size: 30px;
        width: 100%;

        .heading-line {
          height: 21px;
          display: flex;
          flex: 1;
          margin-left: 5px;
          margin-top: 13px;
          border-bottom: 2px solid #bebebe;
          // border-top: 2px solid #bebebe;
        }
      }

      .event-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px; padding-left: 20px;
        border-left: 5px solid #bebebe;
        position: relative;

        &.completed {
          border-color: $completed-color;
        }
        &.unfinished {
          border-color: $unfinished-color;
        }
        &.overdue {
          border-color: $overdue-color;
        }

        .event-info-container {
          display:flex;
          flex-direction: column;
          flex: 1;
  
          .account-name {
            font-size: 18px;
            height: 30px;
            margin-bottom: 0;
          }

          .event-note {
            height: 20px;
            min-height: 20px;
            // display: flex;
            // flex: 1;
            color: grey;
            font-style: italic;
            padding: 0;
            line-height: 15px;
            border-bottom: 0;
          }

          .event-time {
            width: 90px;
            margin-left: 10px;
            text-align: center;
            height: 33px;
            margin-bottom: 0;
            border: 0;
            font-size: 20px;
          }

          .time-icon {
            cursor: pointer;
            border-radius: 10px;
            box-shadow: 0 0 5px 2px rgb(207, 207, 207);
            color: #009dfd;
            background-color: rgb(255, 255, 255);
            margin: 0px 5px 0px 10px;
            padding: 2px 10px;
            font-size: 30px;
            width: 85px; height: 30px;
          }

          .scheduling-info {
            display: flex;
            padding: 5px 0;

            span {
              background-color: #747474;
              color: white;
              padding: 5px 10px;
              border-radius: 15px;
              margin-right: 10px;

              &.overdue {
                background-color: #aa0071;
              }
              &.completed {
                background-color: #00aa69;
              }
              &.unfinished {
                background-color: #0074aa;
              }
            }

            .pill {
              height: unset;
            }
          }
        }

        .buttons-container {
          // min-width: 90px;
          display: flex;
          flex-direction: column;
          padding: 10px;
          justify-content: space-between;

          .button {
            line-height: 10px;
            border: 0;
            border-radius: 10px;
            height: 30px; width: 80px;
            color: rgb(255, 255, 255);
  
            &.delete-event {
              background-color: #8c0002;
              box-shadow: 0 0 5px 2px rgb(177, 177, 177);
  
              &:active {
                background-color: #4d0001;
              }
            }
  
            &.repeat-event {
              background-color: #ffa400;
              box-shadow: 0 0 5px 2px rgb(207, 207, 207);
  
              &:active {
                background-color: #815800;
              }
            }

            &.reschedule {
              background-color: #009dfd;
              box-shadow: 0 0 5px 2px rgb(207, 207, 207);
            }
          }

          .hidden-input {
            display: none;
          }
        }

        .event-repeat-options-container, .event-delete-confirmation-container, .event-reschedule-options-container {
          position:absolute;
          background-color: rgba(184, 184, 184, 0.8);
          width: 100%;
          height: 100%;
          left: 0px;
          display: flex;
          flex-direction: column;
          padding-right: 0px;
          padding-top: 0px; 

          .buttons-container {
            display: flex;
            flex-direction: row;

            button {
              display: flex;
              flex: 1;
              margin: 5px;
              justify-content: center;
              align-items: center;
              height: 80px;
              border: 0;
              border-radius: 10px;
              border: 2px solid grey;
              font-size: 18px;
              background-color: rgba(255, 255, 255, 0.7);

              &.delete-final {
                background-color: #ff8a8a;
              }
            }
          }

        }

        button.hidden {
          display: none;
        }
      }

      .suggested-events {
        display: flex;
        flex-direction: column;

        .suggested-header {
          padding-left: 15px;
          font-size: 18px;
          color: grey;
        }

        button.suggestion {
          height: 30px;
          margin-left: 15px;
          background-color: white;
          border: 2px dashed rgb(182, 182, 182);
          border-radius: 5px;
          margin-bottom: 10px;
          transition: border .2s, background-color .2s, box-shadow .2s;
          box-shadow: 0 0 0px 0px rgb(207, 207, 207);

          &:hover {
            border: 2px solid rgb(182, 182, 182);
            background-color: rgb(182, 182, 182);
            box-shadow: 0 0 5px 2px rgb(207, 207, 207);
          }
        }
      }
    }
  }

  .pill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #747474;
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    margin: 5px 5px 0 0;
    height: 30px;
    min-width: 30px;

    &.overdue {
      background-color: $overdue-color !important;
    }

    &.completed {
      background-color: $completed-color !important;
    }

    &.unfinished {
      background-color: $unfinished-color !important;
    }

    &.first {
      background-color: #874caf
    }

    &.service-tech {
      background-color: #00547a;
    }

    svg {
      margin-right: 5px;
    }
  }

  button.pill {
    border: 0;
    box-shadow: 0px 0px 3px rgb(87, 87, 87);
    flex-shrink: 0;
  }
}